import { db } from '@/firebase'
import { ARTIST_COLLECTION } from '@/constants/collections'

const artistsRef = db.collection(ARTIST_COLLECTION)

export async function getArtistsData() {
  let response
  let error
  try {
    const snapshot = await artistsRef.orderBy('artistID', 'desc').get()
    response = snapshot.docs.map((doc) => doc.data())
  } catch (e) {
    error = e
  }

  return { response, error }
}

export const getArtistBySlug = async (artistSlug) => {
  let response
  let error
  try {
    const snapshot = await artistsRef
      .where('artistSlug', '==', artistSlug)
      .limit(1)
      .get()
    response = snapshot.docs[0].data()
  } catch (e) {
    error = e
  }

  return { response, error }
}
